<template>
  <div class="short_video">
    <div class="back" @click="$router.go(-1)">
      <svg-icon icon-class="back" />
    </div>
    <!-- <v-touch @swipeleft="swipeLeft" @swiperight="swipeRight" :swipe-options="{direction: 'horizontal'}" class="touch"> -->
    <v-touch :swipe-options="{ direction: 'horizontal' }" class="touch">
      <transition name="slide-r">
        <div class="video-s">
          <swiper class="video-swiper" :options="swiperOption" ref="mySwiper">
            <swiper-slide
              class="my_swiper_silde"
              v-for="(item, index) in communityObj.list"
              :key="'item.id' + index"
            >
              <Video
                :timeStr="timeStr"
                :newUserTime="newUserTime"
                @openCmt="openComment"
                @openShare="openShare"
                :videoShow="communityObj.index == index"
                :videoInfo="item"
                :index="index"
              />
            </swiper-slide>
          </swiper>
        </div>
      </transition>
    </v-touch>
    <comment
      v-show="commentShow"
      :topicInfo="topicInfo"
      @commentSuccess="commentSuccess"
      :commentShow="commentShow"
      @commentClose="commentShow = false"
    />
    <share
      v-if="shareShow"
      :topicInfo="topicInfo"
      @shareSuccess="shareSuccess"
      :shareShow="shareShow"
      @shareClose="shareShow = false"
    />
  </div>
</template>
<script>
import { queryVipCards } from "@/api/mine/vip.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Video from "./video";
import { queryVidList } from "@/api/shortVideo";
import { mapGetters } from "vuex";
import {
  getSessionItem,
  setSessionItem,
  removeSession,
} from "@/utils/longStorage";
import comment from "@/views/widget/comment.vue";
import share from "@/views/widget/share.vue";

export default {
  name: "communityVideo",
  components: {
    Video,
    swiper,
    swiperSlide,
    comment,
    share,
  },
  computed: {
    ...mapGetters({
      communityObj: "communityObj",
    }),
  },
  data() {
    return {
      timeStr: "",
      newUserTime: 0,
      pageNumber: 1, //关注数据页码
      videoSize: 10, //一页短视频的条数
      videoList: [],
      commentShow: false, //评论是否显示
      shareShow: false, // 分享是否显示
      topicInfo: {},
      swiperOption: {
        direction: "vertical",
        on: {
          slideChangeTransitionEnd: () => {
            let activeIndex = this.$refs.mySwiper.swiper.activeIndex;
            this.$store.commit("video/UPDATE_COMMUNITY_INDEX", activeIndex);
            let { list, index, isRecommend } = this.communityObj;
            // //滑到倒数第二条数据加载下一页视频数据
            if (index === list.length - 2) {
              if (isRecommend) {
                this.pageNumber++;
                this.getVidList();
              }
            }
            // 如果数据大于3页就删除1页数据
            if (
              index >= 2 * this.videoSize &&
              list.length >= 3 * this.videoSize
            ) {
              this.$refs.mySwiper.swiper.slideTo(
                index - this.videoSize,
                0,
                true
              );
              //切换到第二页删除第一页数据
              this.$store.commit(
                "video/UPDATE_COMMUNITY_INDEX",
                index - this.videoSize
              );
              this.$store.commit("video/DELETE_COMMUNITY_LIST", {
                index: this.videoSize,
              });
            }
          },
        },
      },
    };
  },
  created() {
    this.getVip()
    this.$store.dispatch("user/setWallet");
    if (this.communityObj?.list?.length) {
      setSessionItem("communityObj", JSON.stringify(this.communityObj));
    } else {
      let communityObj = JSON.parse(getSessionItem("communityObj"));
      this.$store.commit("video/SET_COMMUNITY_LIST", {
        list: communityObj.list,
        isRecommend: false,
      });
      this.$store.commit("video/UPDATE_COMMUNITY_INDEX", communityObj.index);
    }
    if (this.communityObj?.list?.length) {
      if (getSessionItem("followInfo")) {
        let followInfo = JSON.parse(getSessionItem("followInfo"));
        this.communityObj.list.forEach((i) => {
          if (followInfo.uid == i.author.id) {
            i.author.acred = followInfo.status;
          }
        });
      }
      this.$nextTick(() => {
        if (this.communityObj?.index >= this.communityObj.list.length) {
          this.$refs.mySwiper.swiper.slideTo(0, 0, true);
          this.$store.commit("video/UPDATE_COMMUNITY_INDEX", 0);
        } else {
          this.$refs.mySwiper.swiper.slideTo(this.communityObj?.index, 0, true);
        }
      });
    }
    if (this.communityObj?.isRecommend) {
      this.getVidList();
    }
  },
  methods: {
    swipeLeft() {
      let uid = this.communityObj?.list[this.communityObj?.index]?.author?.id;
      this.$router.push({
        path: "/bloggerDetails",
        query: {
          id: uid,
        },
      });
    },
    swipeRight() {
      this.$router.go(-1);
    },
    // 获取vip
    async getVip() {
      let ret = await this.$Api(queryVipCards);
      if (ret && ret.code == 200) {
        let vipCardList = ret.data.list.filter((i) => {
          return i.cardType == 1;
        });
        let newUserArr = vipCardList.filter((i) => {
          return i.newUserLeftTime;
        });
        this.newUserTime = newUserArr[0] ? newUserArr[0].newUserLeftTime : 0;
        this.countdown();
      }
    },
    // 倒计时
    countdown() {
      let msec = this.newUserTime;
      if (msec <= 0) return;
      // 计算时分秒数
      let hr = parseInt((msec / 60 / 60) % 24);
      let min = parseInt((msec / 60) % 60);
      let sec = parseInt(msec % 60);
      // 个位数前补零
      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      this.timeStr = `新人限时年卡 ${hr}:${min}:${sec}`;
      // 一秒后递归
      setTimeout(() => {
        this.newUserTime--;
        this.countdown();
      }, 1000);
    },
    //获取视频推荐列表
    async getVidList() {
      let req = {
        id: "post_video",
        pageNum: this.videoPage,
        pageSize: this.videoSize,
        tags: [],
      };
      let ret = await this.$Api(queryVidList, req);
      if (ret && ret.code === 200) {
        if (ret.data.mediaInfos !== null) {
          let videoList = this.communityObj?.list?.concat(ret.data.mediaInfos);
          this.$store.commit("video/SET_COMMUNITY_LIST", {
            list: videoList,
          });
        }
      }
    },
    openComment(data) {
      this.commentShow = true;
      this.topicInfo = data;
    },

    openShare(data) {
      this.shareShow = true;
      this.topicInfo = data;
    },
    commentSuccess() {
      //评论成功评论条数加一
      let index = this.communityObj.index;
      this.communityObj.list[index].commentNum++;
      localStorage.setItem("videoList", JSON.stringify(this.communityObj));
    },
    shareSuccess() {
      //分享成功回调
      let index = this.communityObj.index;
      this.communityObj.list[index].shareNum++;
      localStorage.setItem("videoList", JSON.stringify(this.communityObj));
    },
  },
  //恢复初始状态
  beforeDestroy() {
    getSessionItem("followInfo") || removeSession("followInfo");
  },
};
</script>
<style lang="scss" scoped>
.short_video {
  height: 100%;
  width: 100%;
  background: #000000;
  position: relative;

  .back {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    padding: 5px;
    border-radius: 50%;
    top: 20px;
    left: 15px;
    z-index: 11;

    > svg {
      font-size: 24px;
    }
  }

  .bottom-all {
    height: 100%;
    position: relative;
  }

  .video-s {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;

    .video-swiper {
      height: 100%;
      width: 100%;

      .my_swiper_silde {
        width: 100%;
        height: 100%;
      }
    }
  }

  .touch {
    height: 100%;

    .video-r {
      height: 100%;

      .l_nodata_box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .l_nodata {
          text-align: center;

          .nodata-top {
            font-size: 18px;
          }

          .nodata-bottom {
            margin-top: 15px;
            font-size: 14px;
            color: #919191;
          }
        }
      }

      .video-swiper {
        height: 100%;
        width: 100%;

        .my_swiper_silde {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
